import { Section, Home, About, Projects } from './dataTypes';

// For Header Navigation
export const sections: Array<Section> = ['Home', 'About', 'Projects', 'Resume', 'Contact'];

export const home: Home = {
    profilePicLocation: '/images/JeffProfilePic.jpeg',
    introParagraph:
        'Hi, my name is Jeffrey Fleer. A passionate Full-Stack Engineer specializing in React.js and modularized full-stack web applications. Based in Denver, Colorado.',
    careerTitle: 'Senior Software Engineer',
    asideVerbiage: 'Tech Stack',
    linkedInLink: 'https://www.linkedin.com/in/jeffrey-fleer/',
    gitHubLink: 'https://github.com/Fleerless'
};

export const about: About = {
    chapter: 'About Me',
    title: 'Senior Software Engineer based in Denver, Colorado',
    paragraph:
        'As a Senior Software Engineer, I possess an impressive arsenal of skills in full-stack web development cultivated over the past 5 years. I specialize in React.js with Typescript, modularized full-stack web applications, and Spring Boot services. I am passionate about creating and maintaining high-quality, scalable, and efficient code. A team player and a problem solver, I am always eager to learn new technologies. My expertise includes: React.js, Typescript, Java, Spring Boot, Jest, Mockito, Cypress, Logz, AWS, Dynatrace, REST, Jenkins, Rally/Jira, Node.js, and Express.js.'
};
export const resume = {
    numberOfPages: 2
};

export const projects: Projects = [
	{
		name: 'Fantasy Disc Golf',
		description: [
			'An app for disc golf enthusiasts to create and manage their own fantasy disc golf leagues. The intention for this app is to sell it to an online retailer (infinitediscs.com), as a means to increase customer engagement and retention.',
			'There will be multiple stages of development for this application. UI/UX design, Development, Community Testing, Refinement, Marketing, Integration, and Maintenance. This project is currently in the UI/UX Design stage, using Figma to create wireframes and mockups. Once the basic wireframe is complete, it will be tested by a focus group of current fantasy sports players to get input about click through patterns and available features. At least one UI/UX refinement stage will occur.',
			'Currently, data is being scraped from DGPT.com and PDGA.com. I have had discussions with the PDGA and they will make an API available sometime after Q2 2025. They do have for profit businesses that are paying for access to the depricated API, like StatMando.com, who aggregate and calculate player and couse metrics. Provided I do not store scraped data, I am approved to use data scraped from their public facing sites to display in my application.',
			'Motivations for this project are two-fold. First, to increase my skills as a complete lifecycle developer. My career up to this point has mainly focused on Front-End development. This is an opportunity to broaden my skill set and become an asset to my team during difficult and unforseen outages.  Second, there is only one Fantasy Disc Golf servicer and their app is not fully functioning. There is a clear hole in the market, and I need to be ready when the PDGA releases the new version of their API. It is very likely that other engineers are working on a similar project.',
		],
		technologies: ['React', 'Typescript, Node.js'],
		githubLink: 'https://github.com/Fleerless/FantasyDiscGolf',
		deployedLink: 'string',
		screenShot1: '/images/Projects/FantasyDiscGolf/HomeImage.jpg',
		screenShot2: '/images/Projects/FantasyDiscGolf/EventsImage.jpg',
	},
];